import React from "react"

import { graphql } from "gatsby"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// import { Link } from "gatsby"
// import ReactImageZoom from "react-image-zoom"

import { Magnifier } from "react-image-magnifiers"

import postawKawe from "../images/buycoffeeto-btn-grey-outline.svg"
// import postawKawe from "../images/buycoffeeto-btn-primary-outline.svg"

import SEO from "../components/seo"

import Layout from "./layout"
import Profil from "./Profil"
import Brand from "./Brand"
import Proces from "./Proces"
import Social from "./Social"

const RumDetail = ({ data }) => {
  const {
    marka,
    slug,
    published,
    cena,
    coo,
    styl,
    typ,
    producent,
    regulacje,
    surowiec,
    destylacja,
    starzenie,
    cukier,
    dodatki,
    alkohol,
    imagefront,
    tekst: { json },
  } = data.post

  const options = {
    renderNode: {
      "heading-3": node => {
        return <Heading>{node.content[0].value}</Heading>
      },
      hyperlink: node => {
        return (
          <ExternalLink
            href={node.data.uri}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.content[0].value}
          </ExternalLink>
        )
      },
    },
    renderText: text =>
      text.split("<br>").flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  }

  const content = `Rum sklasyfikowany jako ${typ}. Reprezentuje styl: ${styl}. Pochodzenie: ${coo}. Dowiedz się więcej o: "${marka}"`

  return (
    <Layout>
      <SEO title={marka} description={content} />

      <ExamWrapper>
        <Brand marka={marka} slug={slug} />

        <Profil
          marka={marka}
          producent={producent}
          regulacje={regulacje}
          cena={cena}
          coo={coo}
          styl={styl}
          typ={typ}
        />

        {/* <Zoomer>
          <ReactImageZoom
            img={imagefront.fluid.src}
            zoomPosition="original"
            width={500}
            zoomWidth={200}
          />
        </Zoomer> */}

        <Proces
          surowiec={surowiec}
          destylacja={destylacja}
          starzenie={starzenie}
          cukier={cukier}
          dodatki={dodatki}
          alkohol={alkohol}
        ></Proces>

        <Tekst>{documentToReactComponents(json, options)}</Tekst>

        <MagnifierWrapper>
          <Magnifier imageSrc={imagefront.fluid.src} imageAlt={marka} />
        </MagnifierWrapper>

        <Social marka={marka} slug={slug} />
        <ExamDate>
          Opublikowano ※ <span>{published}</span>
        </ExamDate>

        <LinkKawa href={`https://buycoffee.to/rumblog`}>
          <a
            href="https://buycoffee.to/rumblog"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={postawKawe} alt="Postaw mi kawę na buycoffee.to" />
          </a>
        </LinkKawa>
      </ExamWrapper>
    </Layout>
  )
}

const ExamWrapper = styled.div`
  max-width: 92%;
  margin: 2rem auto 1rem;
  display: grid;

  grid-template-areas:
    "brand"
    "profil"
    "proces"
    "pretext"
    "zoomer"
    "kawa"
    "social"
    "examdate";

  @media (min-width: 900px) {
    margin: 4rem auto 2rem;
  }
`

const Tekst = styled.span`
  grid-area: pretext;
  /* text-align: justify; */
  font-family: var(--montserrat);
  width: 96%;
  margin: 0 auto 1.5rem;
  /* line-height: 150%; */
  line-height: 1.35;

  @media (min-width: 800px) {
    width: 80%;
    line-height: 1.4;
  }

  @media (min-width: 900px) {
    width: 75%;
  }

  @media (min-width: 1050px) {
    width: 70%;
  }

  @media (min-width: 1150px) {
    line-height: 1.45;
    width: 65%;
  }

  @media (min-width: 1250px) {
    width: 60%;
  }

  @media (min-width: 1350px) {
    width: 55%;
  }

  @media (min-width: 1450px) {
    line-height: 1.5;
    width: 50%;
  }

  text-align: left;

  p {
    font-size: 1rem;

    @media (min-width: 412px) {
      /* text-align: justify; */
      text-align: left;
    }

    @media (min-width: 500px) {
      font-size: 1.1rem;
    }

    @media (min-width: 700px) {
      font-size: 1.2rem;
    }
  }
`

const MagnifierWrapper = styled.div`
  grid-area: zoomer;
  margin: 2rem auto 1.5rem;
  width: 80%;

  /* @media (min-width: 500px) {
    padding: 2rem 1.5rem;
  } */

  @media (min-width: 600px) {
    width: 86%;
    /* padding: 2rem 2rem; */
  }
  @media (min-width: 800px) {
    width: 76%;
    /* padding: 2rem 4rem; */
  }
  @media (min-width: 1100px) {
    width: 68%;
    /* padding: 2rem 6rem; */
  }
  @media (min-width: 1300px) {
    width: 50%;
  }
`

// const Zoomer = styled.div`
//   grid-area: zoomer;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   margin: 0 auto 2rem;

//   @media (min-width: 600px) {
//     margin-top: 2rem;
//     width: 100%;
//   }
// `

const Heading = styled.div`
  font-size: 1.8rem;

  text-align: center;
  /* letter-spacing: 2px; */

  font-family: "Spartan", sans-serif;
  text-transform: uppercase;

  margin: 2.5rem auto 1rem;
`

const ExternalLink = styled.a`
  color: #424242;
  text-decoration: underline #424242;
`

const LinkKawa = styled.div`
  grid-area: kawa;

  cursor: pointer;
  text-decoration: none;
  text-align: center;

  margin: 1rem auto 0;
  width: 45%;

  transition: 0.25s;

  &:hover {
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.15));
  }

  @media (min-width: 500px) {
    width: 35%;
  }

  @media (min-width: 600px) {
    width: 30%;
  }
  @media (min-width: 950px) {
    width: 25%;
  }

  @media (min-width: 1050px) {
    width: 20%;
  }
`

const ExamDate = styled.div`
  grid-area: examdate;
  width: 100%;
  text-align: center;
  /* font-family: var(--poiret); */
  margin: 2rem auto 1rem;
`

export const query = graphql`
  query getPost($slug: String!) {
    post: contentfulBlog(slug: { eq: $slug }) {
      slug
      marka
      cena
      coo
      styl
      typ
      producent
      regulacje
      surowiec
      destylacja
      starzenie
      cukier
      dodatki
      alkohol
      published(formatString: "LL", locale: "pl")
      id: contentful_id
      imagefront {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      tekst {
        json
      }
    }
  }
`

export default RumDetail
