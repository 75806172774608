import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"

import {
  // EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  // EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

export default function Social({ marka, slug }) {
  return (
    <SocialMedia>
      <WhatsappShareButton url={`https://rumblog.pl/${slug}`} title={marka}>
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>

      <TwitterShareButton
        url={`https://rumblog.pl/${slug}`}
        // url={location.href}
        via="_MsLinda"
        hashtags={["rum"]}
      >
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <FacebookShareButton url={`https://rumblog.pl/${slug}`}>
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      {/* <EmailShareButton url={`https://rumblog.pl/${slug}`} title={marka}>
      <EmailIcon size={40} round={true} />
    </EmailShareButton> */}
    </SocialMedia>
  )
}

const SocialMedia = styled.div`
  grid-area: social;
  width: 80%;
  text-align: center;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin: 1.25rem auto;

  button svg {
    width: 50px;
    height: 50px;

    &:hover {
      box-shadow: rgb(38, 57, 77) 0px 10px 15px -5px;
      border-radius: 50%;
      border-width: 0;
      border: none;
      outline: none;
    }
  }

  .react-share__ShareButton {
    margin: 0 1rem;
  }

  @media (min-width: 400px) {
    button svg {
      width: 45px;
      height: 45px;
    }

    .react-share__ShareButton {
      margin: 0 1.5rem;
    }
  }

  @media (min-width: 600px) {
    width: 40%;

    .react-share__ShareButton {
      margin: 0 2rem;
    }
  }
`
