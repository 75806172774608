import React from "react"

import styled from "styled-components"

import companyIcon from "../images/company.svg"
import priceIcon from "../images/money.svg"
import rulesIcon from "../images/balance.svg"
import cooIcon from "../images/globe.svg"
import classIcon from "../images/diploma.svg"
import styleIcon from "../images/dress.svg"

export default function Profil({ producent, coo, regulacje, styl, typ, cena }) {
  return (
    <ProfilWrapper>
      <ProfilElement>
        <ProfilIcon src={companyIcon} alt="producent" />
        <p>Producent:</p>
        <p>{producent}</p>
      </ProfilElement>

      <ProfilElement>
        <ProfilIcon src={cooIcon} alt="pochodzenie" />
        <p>Pochodzenie:</p>
        <p>{coo}</p>
      </ProfilElement>

      <ProfilElement>
        <ProfilIcon src={rulesIcon} alt="regulacje" />
        <p>Regulacje:</p>
        <p>{regulacje}</p>
      </ProfilElement>

      <ProfilElement>
        <ProfilIcon src={styleIcon} alt="styl" />
        <p>Styl:</p>
        <p>{styl}</p>
      </ProfilElement>

      <ProfilElement>
        <ProfilIcon src={classIcon} alt="klasyfikacja" />
        <p>Klasyfikacja:</p>
        <p>{typ}</p>
      </ProfilElement>

      <ProfilElement>
        <ProfilIcon src={priceIcon} alt="cena" />
        <p>Cena:</p>
        <p>{cena} &#8364;</p>
      </ProfilElement>
    </ProfilWrapper>
  )
}

const ProfilWrapper = styled.div`
  grid-area: profil;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 1.5rem auto 1rem;

  @media (min-width: 600px) {
    margin: 2.5rem auto 1.5rem;
  }

  @media (min-width: 900px) {
    width: 75%;
  }

  p {
    font-size: 1rem;

    @media (min-width: 500px) {
      font-size: 1.1rem;
    }

    @media (min-width: 700px) {
      font-size: 1.2rem;
    }
  }
`

const ProfilElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  /* flex-basis: 140px; */
  flex-basis: 33%;
  margin: 16px 8px;

  @media (min-width: 600px) {
    /* flex-basis: 210px; */
    flex-basis: 25%;
    margin: 20px 12px;
  }

  p:nth-of-type(1) {
    font-family: "Spartan", sans-serif;
    margin: 5px 0 8px;
  }

  p:nth-of-type(2) {
    /* font-family: "Open Sans", sans-serif; */
  }
`

const ProfilIcon = styled.img`
  justify-content: flex-start;
  width: 50px;
`
