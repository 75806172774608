import React from "react"

import styled from "styled-components"

import rawIcon from "../images/sugar-cane.svg"
import distillationIcon from "../images/distillation.svg"
import agingIcon from "../images/barrel.svg"
import proofIcon from "../images/earning.svg"
import sugarIcon from "../images/sugar.svg"
import additivesIcon from "../images/pouring.svg"

export default function Proces({
  surowiec,
  destylacja,
  starzenie,
  cukier,
  dodatki,
  alkohol,
}) {
  return (
    <ProcesWrapper>
      <ProcesElement>
        <ProcesIcon src={rawIcon} alt="surowiec" />
        <p>Surowiec:</p>
        <p>{surowiec}</p>
      </ProcesElement>

      <ProcesElement>
        <ProcesIcon src={distillationIcon} alt="destylacja" />
        <p>Destylacja:</p>
        <p>{destylacja}</p>
      </ProcesElement>

      <ProcesElement>
        <ProcesIcon src={agingIcon} alt="starzenie" />
        <p>Starzenie:</p>
        <p>{starzenie}</p>
      </ProcesElement>

      <ProcesElement>
        <ProcesIcon src={sugarIcon} alt="dodatek cukru" />
        <p>Dodatek cukru:</p>
        <p>{cukier}</p>
      </ProcesElement>

      <ProcesElement>
        <ProcesIcon src={additivesIcon} alt="domieszki" />
        <p>Domieszki:</p>
        <p>{dodatki}</p>
      </ProcesElement>

      <ProcesElement>
        <ProcesIcon src={proofIcon} alt="alkohol" />
        <p>Alc/vol:</p>
        <p>{alkohol}&#37;</p>
      </ProcesElement>
    </ProcesWrapper>
  )
}

const ProcesWrapper = styled.div`
  grid-area: proces;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem auto;

  @media (min-width: 600px) {
    margin: 1.5rem auto 1.5rem;
  }

  @media (min-width: 900px) {
    width: 75%;
  }

  p {
    font-size: 1rem;

    @media (min-width: 500px) {
      font-size: 1.1rem;
    }

    @media (min-width: 700px) {
      font-size: 1.2rem;
    }
  }
`

const ProcesElement = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  text-align: center;

  /* flex-basis: 140px; */
  flex-basis: 33%;
  margin: 16px 8px;

  @media (min-width: 600px) {
    /* flex-basis: 210px; */
    flex-basis: 25%;
    margin: 20px 12px;
  }

  p:nth-of-type(1) {
    font-family: "Spartan", sans-serif;
    margin: 5px 0 8px;
  }
`

const ProcesIcon = styled.img`
  justify-content: flex-start;
  width: 50px;
`
